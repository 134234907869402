import { mapActions, mapGetters, mapMutations } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/uk";
import banner from "../../home/components/banner/index.vue";
import { globalLoader } from "@/helpers/variables";
import { downloadFileFrom } from "@/helpers";
import moment from "moment";
import TrainingBanner from "../traching-banner.vue";

const SeminarTypes = {
  SEMINAR: "seminars",
  BRANCH: "branches",
  CONFERENCES: "conferences",
};

export default {
  name: "seminars",
  components: {
    TrainingBanner,
    DatePicker,
    banner,
  },
  data() {
    return {
      loadingSeminars: false,
      filteringSeminars: false,
      filters: null,
      seminarsList: [],
      paginator: {
        hasNextPage: false,
        page: 1,
        perPage: 10,
      },
      dates: null,
      city: "",
      seminar: "",
      theme: "",
    };
  },
  async created() {
    globalLoader(true);

    console.log(this.$route.params?.slug);
    await Promise.all([
      this.fetchPage("seminars"),
      this.fetchFilters(this.$route.params?.slug).then(filters => (this.filters = filters)),
      this.loadSeminars(),
    ]);

    globalLoader(false);
  },
  computed: {
    ...mapGetters({
      pageContent: "pages/page",
      isAuthenticated: "auth/isAuthenticated",
    }),
    isSeminar() {
      return this.$route.params.slug === SeminarTypes.SEMINAR;
    },
    isBranch() {
      return this.$route.params.slug === SeminarTypes.BRANCH;
    },
    isConferences() {
      return this.$route.params.slug === SeminarTypes.CONFERENCES;
    },
    checkPdfAvailable() {
      const cityFileExists = !!this.city && this.filters.cities.find(item => item.title === this.city)?.file;
      return !cityFileExists;
    },
  },
  watch: {
    dates() {
      this.$refs.errorForm.textContent = "";
    },
    city() {
      this.$refs.errorForm.textContent = "";
    },
    seminar() {
      this.$refs.errorForm.textContent = "";
    },
    search() {
      this.$refs.errorForm.textContent = "";
    },
  },
  methods: {
    ...mapMutations({
      changeLoginPopup: "popups/CHANGE_LOGIN_POPUP",
      changeSeminarRegistration: "seminars/SHOW_SEMINAR_POPUP",
      saveCurrentSeminar: "seminars/SAVE_CURRENT_SEMINAR",
      changeSeminarDetails: "popups/SHOW_SEMINAR_DETAILS_POPUP",
      showTextInfoModal: "popups/CHANGE_TEXT_INFO_MODAL",
    }),
    ...mapActions({
      fetchPage: "pages/GET_PAGE",
      fetchSeminars: "seminars/GET_SEMINARS",
      fetchFilters: "seminars/GET_SEMINAR_FILTERS",
    }),
    getCurrentMonthUK() {
      moment.locale("uk");
      return moment().format("MMMM YYYY");
    },
    async downloadPdf() {
      const link = this.filters.cities.find(item => item.title === this.city).file;
      await downloadFileFrom(link, "Семінари та заходи.pdf");
    },
    showSeminarPopup(detail, title) {
      this.showTextInfoModal({
        status: true,
        data: {
          title: title,
          detail: detail,
        },
      });
    },
    async resetFilters() {
      this.filteringSeminars = true;

      this.dates = null;
      this.city = "";
      this.seminar = "";
      this.theme = "";

      this.resetPage();
      await this.loadSeminars();

      this.filteringSeminars = false;
    },
    disabledData(date) {
      let today = new Date();
      today.setDate(today.getDate() - 1);
      return date < today;
    },
    resetPage() {
      this.paginator.page = 1;
    },
    async loadSeminars() {
      this.loadingSeminars = true;

      const seminarsData = await this.fetchSeminars({
        page: this.paginator.page,
        perPage: this.paginator.perPage,
        kind: this.$route.params.slug,
        ...this.getCurrentFilters(),
      });

      if (this.paginator.page === 1) {
        this.seminarsList = [];
      }

      this.seminarsList.push(...seminarsData.data);

      this.paginator.hasNextPage = !!seminarsData.links.next;

      this.loadingSeminars = false;
    },
    paginateSeminars() {
      this.paginator.page += 1;
      this.loadSeminars();
    },
    /** @param {Seminar} seminar */
    seminarRegister(seminar) {
      this.saveCurrentSeminar(seminar);
      this.changeSeminarRegistration(true);
    },
    /** @return {IFiltersType} */
    getCurrentFilters() {
      /** @type {IFiltersType} */
      let filters = {};
      if (this.dates) {
        let dayFrom = this.dates[0].getDate(),
          monthFrom = this.dates[0].getMonth() + 1,
          yearFrom = this.dates[0].getUTCFullYear(),
          dayTo = this.dates[1].getDate(),
          monthTo = this.dates[1].getMonth() + 1,
          yearTo = this.dates[1].getUTCFullYear();

        filters.date_from = `${yearFrom}-${monthFrom}-${dayFrom}`;
        filters.date_to = `${yearTo}-${monthTo}-${dayTo}`;
      }
      if (this.city) {
        let cityId = this.filters.cities.find(el => el.title === this.city);
        filters.city = cityId.id;
      }
      if (this.seminar) {
        let seminar = this.filters.types.find(el => el.title === this.seminar);
        filters.type = seminar.id;
      }
      if (this.theme) {
        let themeId = this.filters.themes.find(el => el.title === this.theme);
        filters.theme = themeId.id;
      }

      return filters;
    },
    async submit() {
      if (!this.dates && !this.city && !this.seminar && !this.theme) {
        this.$refs.errorForm.textContent = this.$t("allFiltersEmpty");
      } else {
        this.filteringSeminars = true;
        this.resetPage();
        await this.loadSeminars();
        this.filteringSeminars = false;
      }
    },
  },
};
